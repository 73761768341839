var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('h1',[_vm._v("Startgeräte")]),_c('v-data-table',{staticClass:"stbw2023live",attrs:{"items":_vm.geraete(_vm.e[0], _vm.df, true),"headers":[
          { text: 'Gerät', value: 'name', sortable: false },
          { text: 'Mannschaft', value: 'mannschaft', sortable: false }
        ],"mobile-breakpoint":0,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('h2',[_vm._v(_vm._s(item.name))])]}},{key:"item.mannschaft",fn:function(ref){
        var item = ref.item;
return _vm._l((_vm.teamsangeraet(item._discipline)),function(t){return _c('h2',{key:t.team._id},[_vm._v(_vm._s(t.team.name))])})}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('h1',{staticStyle:{"display":"block","margin":"auto","text-align":"center"}},[_vm._v("QR-Code zur Live-Anzeige")]),(_vm.link)?_c('img',{staticStyle:{"width":"80%","height":"auto","margin":"auto","display":"block"},attrs:{"src":_vm.link}}):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }